<template>
  <page-layout title="Notifications" linkDefault="/" :backButton="true">
    <Loading v-if="loading" />
    <div v-else>
      <notification v-for="(notification_, i) in notification" :key="i" :notification="notification_" />
    </div>
  </page-layout>
</template>

<script>
import Loading from "../../components/Loading.vue";
import pageLayout from "../../components/base/pageLayout.vue";
import notification from "../../components/notification.vue"
export default {
  components: { notification, pageLayout, Loading },
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      user: null,
      foo: null,
      notification: [],
      loading: true,
    };
  },
  created() {
    this.user = this.$store.state.user;
    this.notification = this.user.notifications.reverse();
    this.loading = false;
  },

}
</script>

<style lang="scss" scoped>

</style>