<template>
  <div class="w-full py-2 relative" @click="view()" :class="{
      'bg-orange-tint': !notification.is_read, 
      'ring-2': !notification.is_read,
      'ring-orange': !notification.is_read,
  }">
    <p class="float-right font-bold mx-4 text-gray-300 text-xs leading-3 mt-5">{{ relativeDate }}</p>
    <div class="flex items-center mx-4">
      <img v-if="notification.img || this.$store.state.filler_img"
        :src="notification.img || this.$store.state.filler_img" alt="" class="w-10 h-10 rounded-full mr-2" />
      <div class="bg-orange w-4 h-4 rounded-full mx-3" v-else-if="!notification.is_read"></div>
      <div class="flex flex-row mx-2 my-4 ">
        <p class="font-poppins font-extrabold text-xs left-4">{{ notification.headline }} <span
            class=" font-poppins font-normal text-xs "> {{ notification.message }}</span> </p>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useRouter } from "vue-router";
import { mapActions } from "vuex";

export default {
  computed: {
    relativeDate() {
      return dayjs().to(this.date);
    },
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  created() {
    dayjs.extend(relativeTime);
  },
  data() {
    return {
      date: this.notification.date,
    };
  },
  methods: {
    ...mapActions(["read_notif"]),
    view() {
      if (!this.notification.is_read)
        this.read_notif(this.notification._id);
      this.notification.is_read = true;
      if (this.notification.link) this.$router.push(this.notification.link);
    },
  },
  props: {
    notification: Object,
  },
};
</script>